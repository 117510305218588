<template>
  <v-card>
    <v-card outlined>
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("status") }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              @click="getStatus"
              :disabled="loading"
              :loading="loading"
              icon
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh status") }}</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>{{ $t("active") }}</td>
              <td><boolean-value v-model="active" colored /></td>
            </tr>
            <tr>
              <td>{{ $t("disabled") }}</td>
              <td><boolean-value v-model="disabled" colored /></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>

    <v-card outlined class="mt-2" v-if="staticIps.lenght > 0">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("static IPs") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="staticIpsHeaders"
          :items="staticIps"
          :loading="loading"
          :mobile-breakpoint="mbreakpoint"
          sort-by="address"
          :hide-default-footer="staticIps.length <= 15"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [15, 50],
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card outlined class="mt-2" v-if="staticSubnets.lenght > 0">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("static IP subnets") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="staticSubnetsHeaders"
          :items="staticSubnets"
          :loading="loading"
          :mobile-breakpoint="mbreakpoint"
          sort-by="destination"
          :hide-default-footer="staticSubnets.length <= 15"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [15, 50],
          }"
        >
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card outlined class="mt-2" v-if="leases.length > 0">
      <v-toolbar color="titleBar" dark flat dense>
        <v-toolbar-title>{{ $t("IP leases") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :headers="leasesHeaders"
          :items="leases"
          :loading="loading"
          :mobile-breakpoint="mbreakpoint"
          sort-by="time_end"
          :hide-default-footer="leases.length <= 15"
          :footer-props="{
            showFirstLastPage: true,
            itemsPerPageOptions: [15, 50],
          }"
        >
          <template v-slot:item.active="{ item }">
            <boolean-value v-model="item.active" />
          </template>

          <template v-slot:item.dynamic="{ item }">
            <boolean-value v-model="item.dynamic" />
          </template>

          <template v-slot:item.time_start="{ item }">
            <div class="text-no-wrap">
              {{ item.time_start ? formatDateTime(item.time_start) : "" }}
            </div>
          </template>

          <template v-slot:item.time_end="{ item }">
            <div class="text-no-wrap">
              {{ item.time_end ? formatDateTime(item.time_end) : "" }}
            </div>
          </template>

          <template v-slot:item.time_last_request="{ item }">
            <div class="text-no-wrap">
              {{
                item.time_last_request
                  ? formatDateTime(item.time_last_request)
                  : ""
              }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import formatDateTime from "@/utils/mixins/formatDateTime";
import prettyBytes from "@/utils/mixins/prettyBytes";
import BooleanValue from "@/components/basics/BooleanValue";
import isMobile from "@/utils/mixins/isMobile";

const ip = require("@/utils/ip");

export default {
  name: "MetronetStatus",
  components: {
    BooleanValue,
  },
  mixins: [formatDateTime, prettyBytes, isMobile],
  props: {
    productId: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    loading: false,
    status: {},
    leases: [],
    active: false,
    disabled: false,
    staticIps: [],
    staticSubnets: [],
  }),
  watch: {
    productId: "getStatus",
  },
  computed: {
    leasesHeaders() {
      return [
        {
          text: this.$i18n.t("active"),
          value: "active",
        },
        {
          text: this.$i18n.t("start time"),
          value: "time_start",
        },
        {
          text: this.$i18n.t("end time"),
          value: "time_end",
        },
        {
          text: this.$i18n.t("time last request"),
          value: "time_last_request",
        },
        {
          text: this.$i18n.t("IP address"),
          value: "ip",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("IP subnet"),
          value: "subnet",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("dynamic"),
          value: "dynamic",
        },
        {
          text: this.$i18n.t("MAC address"),
          value: "mac",
        },
        {
          text: this.$i18n.t("hostname"),
          value: "hostname",
        },
        {
          text: this.$i18n.t("pool type"),
          value: "pool_type",
        },
      ];
    },
    staticIpsHeaders() {
      return [
        {
          text: this.$i18n.t("network ID"),
          value: "network_id",
        },
        {
          text: this.$i18n.t("IP address"),
          value: "address",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("MAC address"),
          value: "mac",
        },
      ];
    },
    staticSubnetsHeaders() {
      return [
        {
          text: this.$i18n.t("destination"),
          value: "destination",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("IP prefix"),
          value: "prefix",
        },
        {
          text: this.$i18n.t("via"),
          value: "via",
          sort: (a, b) => {
            return ip.compare(a, b);
          },
        },
        {
          text: this.$i18n.t("type"),
          value: "type_name",
        },
        {
          text: this.$i18n.t("status"),
          value: "status",
        },
      ];
    },
  },
  methods: {
    getStatus() {
      if (this.productId != null) {
        var that = this;
        this.loading = true;
        this.$http
          .get("services/metronet/" + this.productId + "/status")
          .then((response) => {
            that.status = { ...response.data };
            that.leases = []
              .concat(that.status.leases_active || [])
              .concat(that.status.leases_history || []);
            that.staticIps = [].concat(that.status.static_ip || []);
            that.staticSubnets = [].concat(that.static_subnets || []);
            that.active = that.status.active || false;
            that.disabled = that.status.disabledi || false;
          })
          .catch((err) => {
            if (err.status != 400) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function() {
            that.loading = false;
          });
      } else this.reset();
    },
    reset() {
      this.status = {};
      this.leases = [];
      this.active = false;
      this.disabled = false;
      this.staticIps = [];
      this.staticSubnets = [];
    },
  },
  watch: {
    "$store.status.session"() {
      this.getStatus();
    },
    productId() {
      this.getStatus();
    },
  },
  mounted: function() {
    this.getStatus();
  },
};
</script>

<i18n>
{
  "en": {
    "status": "Status",
    "refresh status": "refresh status",
    "IP leases": "IP leases",
    "active": "Active",
    "disabled": "Disabled",
    "start time": "Start time",
    "end time": "End time",
    "time last request": "Time last request",
    "IP address": "IP address",
    "IP subnet": "IP subnet",
    "dynamic": "Dynamic",
    "MAC address": "MAC address",
    "hostname": "Hostname",
    "pool type": "Pool type",
    "static IPs": "Static IPs",
    "network ID": "Network ID",
    "static IP subnets": "Static IP subnets",
    "destination": "Destination",
    "IP prefix": "IP prefix",
    "via": "Via",
    "type": "Type"
  },
  "de": {
    "status": "Status",
    "refresh status": "Status aktualisieren",
    "IP leases": "IP Zuweisung",
    "active": "Aktiv",
    "disabled": "Deaktiviert",
    "start time": "Start Zeit",
    "end time": "End Zeit",
    "time last request": "letzte Anforderung",
    "IP address": "IP Adresse",
    "IP subnet": "IP Subnetz",
    "dynamic": "Dynamisch",
    "MAC address": "MAC Adresse",
    "hostname": "Hostname",
    "pool type": "Pooltyp",
    "static IPs": "Static IPs",
    "network ID": "Netz ID",
    "static IP subnets": "Statische IP Subnetze",
    "destination": "Ziel",
    "IP prefix": "IP Prefix",
    "via": "Via",
    "type": "Typ"
  }
}
</i18n>
