<template>
  <v-card>
    <v-dialog v-if="profile" v-model="upgradeDialog" width="80%" persistent>
      <metronet-upgrade
        v-if="upgradeDialog"
        :product="product"
        :metronet-profile="profile"
        :metronet-service="service"
        @upgradeCanceled="upgradeDialog = false"
        @upgradeOrdered="upgradeDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="ipSetupDialog" width="80%" scrollable>
      <ip-setup
        v-if="ipSetupDialog"
        :product="product"
        @close="ipSetupDialog = false"
      />
    </v-dialog>

    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ service.supplier ? service.supplier : "..." }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="
              $router.push({
                name: 'product-details',
                params: { productId: metronetProductId },
              })
            "
            :disabled="loading || !hasProductRole"
            icon
          >
            <v-icon>mdi-text-box</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("open product") }}</span>
      </v-tooltip>

      <v-tooltip v-if="$store.state.session.partnerType == 'WHO'" top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="$router.push({ name: 'internet-order' })"
            :disabled="$store.getters.isReadOnly || !hasOrderRole"
            icon
          >
            <v-icon>mdi-plus-network-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("add new internet service") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            @click="upgradeDialog = true"
            :disabled="
              loading ||
              upgradeDialog ||
              $store.getters.isReadOnly ||
              !hasOrderRole
            "
            icon
          >
            <v-icon>mdi-tune</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("up/downgrade line") }}</span>
      </v-tooltip>

      <wiki slug="services-metronet-details" />
    </v-toolbar>

    <v-progress-linear :active="loading" indeterminate />

    <v-card-text>
      <v-card outlined class="mb-4" v-if="profile">
        <v-toolbar color="titleBar" dark flat dense>
          <v-toolbar-title> {{ profile.product_type_name }}</v-toolbar-title>
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                @click="ipSetupDialog = true"
                :disabled="loading"
                icon
              >
                <v-icon>mdi-ip</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("open IP setup") }}</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <product-attributes :attributes="profile.attributes" />
        </v-card-text>
      </v-card>
      <device-info
        v-if="metronetProductId"
        :product-id="metronetProductId"
        class="mb-4"
      />
      <metronet-status
        :product-id="metronetProductId"
        v-if="metronetProductId"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import MetronetUpgrade from "./MetronetUpgrade";
import MetronetStatus from "./MetronetStatus";
import ProductAttributes from "@/components/products/ProductAttributes";
import DeviceInfo from "@/components/services/internet/DeviceInfo";
import IpSetup from "@/components/services/internet/IpSetup";
import commonAddOns from "@/utils/mixins/commonAddOns";
import Wiki from "@/components/basics/Wiki";

export default {
  name: "MetronetDetails",
  components: {
    MetronetUpgrade,
    MetronetStatus,
    ProductAttributes,
    DeviceInfo,
    IpSetup,
    Wiki,
  },
  mixins: [commonAddOns],
  props: {
    metronetProductId: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      loadingService: false,
      loadingProduct: false,
      upgradeDialog: false,
      ipSetupDialog: false,
      service: {},
      product: {},
      profile: null,
    };
  },
  computed: {
    loading: function () {
      return this.loadingService || this.loadingProduct;
    },
  },
  watch: {
    metronetProductId() {
      this.service = {};
      this.product = {};
      this.profile = null;
      this.getService();
      this.getProduct();
    },
  },
  methods: {
    getService: function () {
      var that = this;
      if (this.metronetProductId) {
        this.loadingService = true;
        this.$http
          .get("services/metronet/" + this.metronetProductId)
          .then((response) => {
            that.service = response.data;
          })
          .catch((err) => {
            if (err.invalid()) {
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
            }
          })
          .finally(function () {
            that.loadingService = false;
          });
      }
    },
    getProduct: function () {
      var that = this;
      if (this.metronetProductId) {
        this.loadingProduct = true;
        this.$http
          .get("products/" + this.metronetProductId, {
            disableDefaultErrorHandler: true,
          })
          .then((response) => {
            that.product = response.data;
            that.product.variants.forEach((item) => {
              if (item.component_code === "PF") that.profile = item;
            });
          })
          .catch((err) => {
            if (err.status == 403) {
              console.log(
                "user or person seems not allowed to see this product details"
              );
            } else
              that.$store.commit("setSystemError", {
                msg: err.message,
                title: err.title,
              });
          })
          .finally(function () {
            that.loadingProduct = false;
          });
      }
    },
  },
  mounted: function () {
    this.getService();
    this.getProduct();
  },
};
</script>

<i18n>
{
  "en": {
    "up/downgrade line": "Up/downgrade line",
    "open product": "Open product",
    "add new internet service": "Add new internet service",
    "open IP setup": "Open IP setup"
  },
  "de": {
    "up/downgrade line": "Anschluss Up/Downgrade",
    "open product": "Produkt öffen",
    "add new internet service": "Neuen Internet Dienst hinzufügen",
    "open IP setup": "IP Einstellungen öffnen"
  }
}
</i18n>
