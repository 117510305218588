var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.profile)?_c('v-dialog',{attrs:{"width":"80%","persistent":""},model:{value:(_vm.upgradeDialog),callback:function ($$v) {_vm.upgradeDialog=$$v},expression:"upgradeDialog"}},[(_vm.upgradeDialog)?_c('metronet-upgrade',{attrs:{"product":_vm.product,"metronet-profile":_vm.profile,"metronet-service":_vm.service},on:{"upgradeCanceled":function($event){_vm.upgradeDialog = false},"upgradeOrdered":function($event){_vm.upgradeDialog = false}}}):_vm._e()],1):_vm._e(),_c('v-dialog',{attrs:{"width":"80%","scrollable":""},model:{value:(_vm.ipSetupDialog),callback:function ($$v) {_vm.ipSetupDialog=$$v},expression:"ipSetupDialog"}},[(_vm.ipSetupDialog)?_c('ip-setup',{attrs:{"product":_vm.product},on:{"close":function($event){_vm.ipSetupDialog = false}}}):_vm._e()],1),_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.service.supplier ? _vm.service.supplier : "...")+" ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading || !_vm.hasProductRole,"icon":""},on:{"click":function($event){return _vm.$router.push({
              name: 'product-details',
              params: { productId: _vm.metronetProductId },
            })}}},on),[_c('v-icon',[_vm._v("mdi-text-box")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("open product")))])]),(_vm.$store.state.session.partnerType == 'WHO')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.$store.getters.isReadOnly || !_vm.hasOrderRole,"icon":""},on:{"click":function($event){return _vm.$router.push({ name: 'internet-order' })}}},on),[_c('v-icon',[_vm._v("mdi-plus-network-outline")])],1)]}}],null,false,3960914714)},[_c('span',[_vm._v(_vm._s(_vm.$t("add new internet service")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading ||
            _vm.upgradeDialog ||
            _vm.$store.getters.isReadOnly ||
            !_vm.hasOrderRole,"icon":""},on:{"click":function($event){_vm.upgradeDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-tune")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("up/downgrade line")))])]),_c('wiki',{attrs:{"slug":"services-metronet-details"}})],1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":""}}),_c('v-card-text',[(_vm.profile)?_c('v-card',{staticClass:"mb-4",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"color":"titleBar","dark":"","flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.profile.product_type_name))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"icon":""},on:{"click":function($event){_vm.ipSetupDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-ip")])],1)]}}],null,false,3080050731)},[_c('span',[_vm._v(_vm._s(_vm.$t("open IP setup")))])])],1),_c('v-card-text',[_c('product-attributes',{attrs:{"attributes":_vm.profile.attributes}})],1)],1):_vm._e(),(_vm.metronetProductId)?_c('device-info',{staticClass:"mb-4",attrs:{"product-id":_vm.metronetProductId}}):_vm._e(),(_vm.metronetProductId)?_c('metronet-status',{attrs:{"product-id":_vm.metronetProductId}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }