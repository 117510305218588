<template>
  <v-simple-table dense>
    <tbody>
      <tr v-for="(attr, i) in attributes" :key="i">
        <td>{{ getDescription(attr.attr_code) }}:</td>
        <td v-if="attr.attr_value">{{ attr.attr_value }}</td>
        <td v-else>-</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<style scoped>
td {
  width: 50%;
}
</style>

<script>
export default {
  name: "ProductAttributes",
  props: {
    attributes: {
      type: Array,
      required: true
    }
  },
  methods: {
    getDescription(attr_code) {
      if (
        this.$store.state.attributeTypes != null &&
        this.$store.state.attributeTypes.hasOwnProperty(attr_code)
      ) {
        var attr = this.$store.state.attributeTypes[attr_code];
        var attr_name = "description_" + this.$store.state.locale;
        if (attr.hasOwnProperty(attr_name))
          return attr[attr_name] || attr.description || attr_code;
        return attr.description || attr.attr_code;
      } else return attr_code;
    },
    getAttributeTypes() {
      if (this.$store.state.attributeTypes == null) {
        var that = this;
        that.$http
          .get("products/attributes")
          .then(response => {
            var attributeTypes = {};
            response.data.forEach(function(attr) {
              attributeTypes[attr.attr_code] = attr;
            });
            this.$store.commit("setAttributeTypes", attributeTypes);
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  },
  created() {
    this.getAttributeTypes();
  }
};
</script>
